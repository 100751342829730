import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  getStoredUser as getStoredUserAction,
  logoutUser as logoutUserAction,
} from "../../redux/modules/login";

import UiHeaderProfile from "../header/UiHeaderProfile";
import UiLogoTitle from "../text/UiLogoTitle";

import "./UiHeader.scss";

function UiHeader(props) {
  const navigate = useNavigate();

  const [openProfile, setOpenProfile] = useState(false);

  return (
    <header className="header">
      <UiLogoTitle link={true} size="f20" />
      <div className="header-cards-wrap"></div>
      <div className="header-profile-wrap">
        <UiHeaderProfile
          active={openProfile}
          onOpen={() => {
            setOpenProfile(true);
          }}
          onClose={() => {
            setOpenProfile(false);
          }}
          onLogout={() => {
            props.logoutUser().then(() => {
              navigate("../login", { replace: true });
            });
          }}
          id={props.login.user ? props.login.user.id : ""}
          user={props.login.user ? props.login.user.email : ""}
          color={ "orange"}
        />
      </div>
    </header>
  );
}

export default connect(({ login }) => ({ login }), {
  getStoredUser: getStoredUserAction,
  logoutUser: logoutUserAction,
})(UiHeader);
