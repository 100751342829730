import React, { useEffect, useState } from "react";

import { ReactComponent as SvgBack } from "../../assets/images/ui/arrow-back.svg";

import "./UiCheckButton.scss";

const UiCheckButton = (props) => {
  const [active, setActive] = useState(false);
  const setCheck = () => {
    let val = !active;
    setActive(val);
    props.onChange(val);
  };

  useEffect(() => {
    setActive(props.value);
  }, [props.value])

  return (
    <button
      className={
        "check-button button-blue" +
        (active ? " active" : "") +
        (props.color ? " button-" + props.color : " button-white") +
        (props.small ? " small" : "")
      }
      onClick={() => setCheck()}
    >
      <span>{props.text}</span>
    </button>
  );
};

export default UiCheckButton;
