import Env from "../../services/Env";
import {
    getList, makeSetting, updateSetting,
    getPriceList, updateSettingByKey
} from "../../services/Setting";

import { storeData, retrieveData, safelyParseJSON } from "../../services/Storage";
import { inArrayValueByKey } from "../../utilities/array";

const moduleName = "settings";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_PRICES = `${moduleName}/SET_PRICES`;
const SET_PRICES_OBJECT = `${moduleName}/SET_PRICES_OBJECT`;

const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
    list: [],
    prices: {
        volume_car: [
            1000, 1200, 1500, 1700, 1900, 2100, 2400, 3000
        ],
        movers: [
            0, 700, 1200, 1600
        ],
        movers_additional: 600,
        distance_km: 100,
        distance_less_25_km: 50,
        distance_then_25_km: 40,

        prr_types: [500, 2500],
        trash_value: [1000, 1500, 2000, 3000],
        tak_value: [750, 2000, 2500, 3000, 3000],

        moving_types: [4000, 5000, 3000, 2000, 0],
        unbuild_value: [1500, 2000, 2500],
        pack_value: [500, 700]
    },
    pricesObjects: {
        volume_car: [],
        movers: [],
        movers_additional: null,
        distance_km: null,
        distance_less_25_km: null,
        distance_then_25_km: null,
        prr_types: [],
        trash_value: [],
        tak_value: [],
        moving_types: [],
        unbuild_value: [],
        pack_value: [],
    },
    error: false,
    errorType: 3,
    inviteCount: 10,
    isFetching: false,

}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_LIST:
            return { ...state, list: payload }
        case SET_PRICES:
            return { ...state, prices: payload }
        case SET_PRICES_OBJECT:
            return { ...state, pricesObjects: payload }
        case SET_ERROR:
            return { ...state, error: payload }
        case SET_ERROR_TYPE:
            return { ...state, errorType: payload }

        case SET_API_REQUEST:
            console.log('fetching API request', payload)
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}


export const setList = (value) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LIST,
            payload: value
        });
    } catch (error) {
        console.error(error);
    }
}


export const getPrice = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getPriceList().then((res) => {
            console.log(res)
            if (res.response) {

                dispatch({
                    type: SET_PRICES,
                    payload: {
                        volume_car: [
                            inArrayValueByKey(res.response, "title", "volume_car_price_0").value, inArrayValueByKey(res.response, "title", "volume_car_price_1").value,
                            inArrayValueByKey(res.response, "title", "volume_car_price_2").value, inArrayValueByKey(res.response, "title", "volume_car_price_3").value,
                            inArrayValueByKey(res.response, "title", "volume_car_price_4").value, inArrayValueByKey(res.response, "title", "volume_car_price_5").value,
                            inArrayValueByKey(res.response, "title", "volume_car_price_6").value, inArrayValueByKey(res.response, "title", "volume_car_price_7").value
                        ],
                        movers: [
                            inArrayValueByKey(res.response, "title", "movers_price_0").value,
                            inArrayValueByKey(res.response, "title", "movers_price_1").value,
                            inArrayValueByKey(res.response, "title", "movers_price_2").value,
                            inArrayValueByKey(res.response, "title", "movers_price_3").value,
                        ],
                        movers_additional: inArrayValueByKey(res.response, "title", "movers_additional_price").value,
                        distance_km: inArrayValueByKey(res.response, "title", "distance_km_price").value,
                        distance_less_25_km: inArrayValueByKey(res.response, "title", "distance_less_25_km_price").value,
                        distance_then_25_km: inArrayValueByKey(res.response, "title", "distance_then_25_km_price").value,

                        prr_types: [
                            inArrayValueByKey(res.response, "title", "prr_types_price_0").value, inArrayValueByKey(res.response, "title", "prr_types_price_1").value
                        ],
                        trash_value: [
                            inArrayValueByKey(res.response, "title", "trash_value_price_0").value, inArrayValueByKey(res.response, "title", "trash_value_price_1").value,
                            inArrayValueByKey(res.response, "title", "trash_value_price_2").value, inArrayValueByKey(res.response, "title", "trash_value_price_3").value,
                        ],
                        tak_value: [
                            inArrayValueByKey(res.response, "title", "tak_value_price_0").value, inArrayValueByKey(res.response, "title", "tak_value_price_1").value,
                            inArrayValueByKey(res.response, "title", "tak_value_price_2").value, inArrayValueByKey(res.response, "title", "tak_value_price_3").value,
                            inArrayValueByKey(res.response, "title", "tak_value_price_4").value,
                        ],

                        moving_types: [
                            inArrayValueByKey(res.response, "title", "moving_types_price_0").value, inArrayValueByKey(res.response, "title", "moving_types_price_1").value,
                            inArrayValueByKey(res.response, "title", "moving_types_price_2").value, inArrayValueByKey(res.response, "title", "moving_types_price_3").value,
                            inArrayValueByKey(res.response, "title", "moving_types_price_4").value,
                        ],
                        unbuild_value: [
                            inArrayValueByKey(res.response, "title", "unbuild_value_price_0").value, inArrayValueByKey(res.response, "title", "unbuild_value_price_1").value,
                            inArrayValueByKey(res.response, "title", "unbuild_value_price_2").value
                        ],
                        pack_value: [
                            inArrayValueByKey(res.response, "title", "pack_value_price_0").value, inArrayValueByKey(res.response, "title", "pack_value_price_1").value
                        ]
                    }
                });
                dispatch({
                    type: SET_PRICES_OBJECT,
                    payload: {
                        volume_car: [
                            inArrayValueByKey(res.response, "title", "volume_car_price_0"), inArrayValueByKey(res.response, "title", "volume_car_price_1"),
                            inArrayValueByKey(res.response, "title", "volume_car_price_2"), inArrayValueByKey(res.response, "title", "volume_car_price_3"),
                            inArrayValueByKey(res.response, "title", "volume_car_price_4"), inArrayValueByKey(res.response, "title", "volume_car_price_5"),
                            inArrayValueByKey(res.response, "title", "volume_car_price_6"), inArrayValueByKey(res.response, "title", "volume_car_price_7")
                        ],
                        movers: [
                            inArrayValueByKey(res.response, "title", "movers_price_0"),
                            inArrayValueByKey(res.response, "title", "movers_price_1"),
                            inArrayValueByKey(res.response, "title", "movers_price_2"),
                            inArrayValueByKey(res.response, "title", "movers_price_3"),
                        ],
                        movers_additional: inArrayValueByKey(res.response, "title", "movers_additional_price"),
                        distance_km: inArrayValueByKey(res.response, "title", "distance_km_price"),
                        distance_less_25_km: inArrayValueByKey(res.response, "title", "distance_less_25_km_price"),
                        distance_then_25_km: inArrayValueByKey(res.response, "title", "distance_then_25_km_price"),

                        prr_types: [
                            inArrayValueByKey(res.response, "title", "prr_types_price_0"), inArrayValueByKey(res.response, "title", "prr_types_price_1")
                        ],
                        trash_value: [
                            inArrayValueByKey(res.response, "title", "trash_value_price_0"), inArrayValueByKey(res.response, "title", "trash_value_price_1"),
                            inArrayValueByKey(res.response, "title", "trash_value_price_2"), inArrayValueByKey(res.response, "title", "trash_value_price_3"),
                        ],
                        tak_value: [
                            inArrayValueByKey(res.response, "title", "tak_value_price_0"), inArrayValueByKey(res.response, "title", "tak_value_price_1"),
                            inArrayValueByKey(res.response, "title", "tak_value_price_2"), inArrayValueByKey(res.response, "title", "tak_value_price_3"),
                            inArrayValueByKey(res.response, "title", "tak_value_price_4"),
                        ],

                        moving_types: [
                            inArrayValueByKey(res.response, "title", "moving_types_price_0"), inArrayValueByKey(res.response, "title", "moving_types_price_1"),
                            inArrayValueByKey(res.response, "title", "moving_types_price_2"), inArrayValueByKey(res.response, "title", "moving_types_price_3"),
                            inArrayValueByKey(res.response, "title", "moving_types_price_4"),
                        ],
                        unbuild_value: [
                            inArrayValueByKey(res.response, "title", "unbuild_value_price_0"), inArrayValueByKey(res.response, "title", "unbuild_value_price_1"),
                            inArrayValueByKey(res.response, "title", "unbuild_value_price_2")
                        ],
                        pack_value: [
                            inArrayValueByKey(res.response, "title", "pack_value_price_0"), inArrayValueByKey(res.response, "title", "pack_value_price_1")
                        ]
                    }
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const getSettingList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getList().then((res) => {
            console.log(res)
            if (res.response) {
                dispatch({
                    type: SET_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const addSetting = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return makeSetting(getState().login.user.api_token, _data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getSettingList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const update = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return updateSetting(_data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getSettingList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const updateByKey = (_title, _value) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        let _data = {
            api_token: getState().login.user.api_token,
            title: _title,
            value: _value
        }


        return updateSettingByKey(_data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getPrice());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

