import React from "react";
import { calculateColor } from '../../utilities/colors';
import UiCardAvatar from "./UiCardAvatar";

import "./UiCardMsg.scss";

const UiCardMsg = (props) => {
  return (
    <div className="card-msg">
      <UiCardAvatar
        avatarColor={props.color ? props.color : calculateColor(props.sender)}
        avatarText={props.sender.substr(0, 2).toUpperCase()}
        size={40}
      />
      <div className="card-msg-wrap">
        <div className="card-msg-sender">
          <p>
            {props.sender.length > 2 ? props.sender : null} {props.date} {props.time ? 'в' : null} {props.time}
          </p>
        </div>
        <p>{props.action}</p>
        <div className="card-msg-info">
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  );
};

export default UiCardMsg;
