import React, { useEffect, useState } from "react";

import info1 from "../../assets/images/info/info2.jpg";
import info2 from "../../assets/images/info/demo1-01.jpg";
import info3 from "../../assets/images/info/info3.jpeg";
import logo from "../../assets/images/info/icon.png";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiSectionTitle from "../../components/header/UiSectionTitle";

import "./HomeStyle.scss";

function LandingPage(props) {
  return (
    <PageContainer
      page="home"
      pageTitle="Муравьи переездов - организация грузоперевозок и переездов"
      pageDescription="Организация грузоперевозок и переездов"
      pageKeywords="Грузоперевозки, переезды, сборка и разборка, упаковка, вывоз мусора"
    >
      <div className="home-about-wrap">
        <header>
          <Container>
            <div className="home-about-header-wrap">
              <img className="home-about-header-logo" src={logo} />
              <div className="home-about-header-title">
                <h2>Муравьи переездов</h2>
                <p>Организация грузоперевозок и переездов</p>
              </div>
              <div className="home-about-header-button">
                {/*<UiButtonColor color="white" onClick={() => {}} text="Войти" />*/}
              </div>
            </div>
          </Container>
        </header>
        <Container>
          <div className="home-about">
            <div className="home-about-images">
              <img className="home-about-image1" src={info1} />
              <img className="home-about-image2" src={info2} />
            </div>
            <div className="home-about-info">
              <UiSectionTitle
                align="left"
                title="Услуги по грузоперевозке и переезду"
                text="Предоставление услуг по упаковке, погрузке, перевозу, разборке и сборке, вывозу мусора"
              />
              <h3>Муравьи переездов</h3>
              <p>
                Справедливые цены на все наши услуги. Ваш переезд будет
                организован быстро и качественно.
              </p>
              <p>
                Проверенные работники и внутренняя система рейтинга перевозчиков
                дает вам гарантию получения качественных услуг.
              </p>
              <p>
                Широкий спектр услуг. Наши работники произведут для вас:
                разборку крупногабаритной мебели, упаковку вещей для их
                дополнительной сохранности, непосредственно перевозку, а также
                сборку по месту прибытия и вывоз мусора. Каждая из услуг
                предоставляется как комплексно, так и по отдельности в
                зависимости от вашего запроса!
              </p>
              <div className="home-button">
              <UiButtonColor
                  href="https://play.google.com/store/apps/details?id=com.antsmoving"
                  color="orange"
                  text="Создать заказ"
                  alt="Создать заказ Муравьи переездов"
                />
              </div>
            </div>
          </div>
          <div className="home-steps">
            <UiSectionTitle
              title="Как мы работаем"
              text="Оставьте заявку и получите отклик на нее от проверенного специалиста"
            />
            <div className="home-steps-list">
              <div className="home-steps-item">
                <h3>1. Вход</h3>
                <p>
                  <span>Быстрый вход потребует только номер телефона</span>
                </p>
                <p>
                  Войдите в сервис, введя свой номер телефона. Это позволит вам
                  легко отследить статус вашей заявки и оставить на нее отклик
                  по результатам выполненной работы. А для нас ваша регистрация
                  - подтверждение номера телефона для оперативной связи с вами.
                </p>
              </div>
              <div className="home-steps-item">
                <h3>2. Заявка</h3>
                <p>
                  <span>Готовая форма для быстрой формулировки целей</span>
                </p>
                <p>
                  Заполните форму заявки. Форма составлена таким образом, чтобы
                  быстро и просто сформулировать вашу задачу, а также сразу
                  рассчитать ее стоимость. Помимо заполнения полей, вы также
                  можете оставить текстовый комментарий и приложить фотографии.
                </p>
              </div>
              <div className="home-steps-item">
                <h3>3. Работа</h3>
                <p>
                  <span>Исполнитель выбирается на основании рейтинга</span>
                </p>
                <p>
                  Как только вы создадите свою заявку - она попадет в систему.
                  Система автоматически предложит ее исполнителям с высоким
                  рейтингом. Таким образом недобросовестные исполнители с низкой
                  оценкой или плохими отзывами не смогут даже подать заявку на
                  ваш заказ.
                </p>
              </div>
              <div className="home-steps-item">
                <h3>4. Результат</h3>
                <p>
                  <span>Оплатите и оставите оценку по итогу исполнения</span>
                </p>
                <p>
                  После выполнения работы вам будет необходимо произвести
                  оплату. Кроме того вы можете оставить отзыв и выставить
                  рейтинг работникам, осуществившим ваш заказ. Это позволит вам
                  и другим пользователям и дальше получать услуги только
                  проверенных исполнителей.
                </p>
              </div>
            </div>
            <div className="home-button">
            <UiButtonColor
                  href="https://play.google.com/store/apps/details?id=com.antsmoving"
                  color="orange"
                  text="Создать заказ"
                  alt="Создать заказ Муравьи переездов"
                />
            </div>
          </div>
          <div className="home-mobile">
            <div className="home-mobile-image-wrap">
              <img className="home-mobile-image" src={info3} />
            </div>
            <div className="home-mobile-info">
              <UiSectionTitle
                align="left"
                title="Приложение"
                text="Для удобства отслеживания заказа и его статуса установите наше приложение"
              />
              <h3>Преимущества установки приложения</h3>
              <p>- Получайте быстрые push уведомления из приложения</p>
              <p>- Отслеживайте ваш заказ по интерактивной карте</p>
              <p>
                - Внутри приложения есть удобные кнопки для быстрой связи с
                исполнителем и службой поддержки
              </p>
              <div className="home-button">
                <UiButtonColor
                  href="https://play.google.com/store/apps/details?id=com.antsmoving"
                  color="orange"
                  text="Создать заказ"
                  alt="Создать заказ Муравьи переездов"
                />
              </div>
            </div>
          </div>
        </Container>
        <footer>
          <Container>
            <p>Политика конфиденциальности и обработки персональных данных</p>
          </Container>
        </footer>
      </div>
    </PageContainer>
  );
}

export default LandingPage;
