import React from "react";

import { ReactComponent as SvgBack } from "../../assets/images/ui/arrow-back.svg";

import "./UiButtonColor.scss";

const UiButtonColor = (props) => {
  return (
    <div className="button-wrap">
      {props.href ? 
      <a
      className={
        "button button-blue" +
        (props.color == "orange" ? " button-orange" : "") +
        (props.color == "primary" ? " button-primary" : "") +
        (props.color == "white" ? " button-white" : "") +
        (props.small ? " small" : "")
      } 
      href={props.href}
      alt={props.alt}
    >
      <span>{props.text}</span>
    </a>
    : 
      <button
      className={
        "button button-blue" +
        (props.color == "orange" ? " button-orange" : "") +
        (props.color == "primary" ? " button-primary" : "") +
        (props.color == "white" ? " button-white" : "") +
        (props.small ? " small" : "")
      }
      onClick={props.onClick}
    >
      <span>{props.text}</span>
    </button>
      }
      
    </div>
  );
};

export default UiButtonColor;
