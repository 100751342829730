
export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  var date = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);

  return `UTC ${date == "-00:00" ? "+00:00" : date}`
}

export const getCurrentTime = () => {
  let current_time = new Date();
  let hh = current_time.getHours();
  let mm = current_time.getMinutes();
  if (hh < 10) hh = '0' + hh;
  if (mm < 10) mm = '0' + mm;
  return [hh, mm].join(':');
}

export const formatDateDMY = (date) => {
  if (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  } else {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  }

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
}

export const formatDatePlusDayDMY = (date, day) => {
  if (date) {
    var d = new Date(date);
    d.setDate(date.getDate() + day);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var  year = d.getFullYear();

  } else {
    var d = new Date();
    d.setDate(d.getDate() + day);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var  year = d.getFullYear();

  }

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
}

export const formatDateHM = (date) => {
  var d = new Date(date),
    hours = d.getHours(),
    minutes = d.getMinutes();

  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;

  return [hours, minutes].join(':');
}