import React from "react";
import { Helmet } from "react-helmet";

import "../../styles/app.scss";

export default function PageContainer({ children, ...props }) {
  return (
    <div className={"page" + (props.page ? " " + props.page : "")}>
      <Helmet>
        <title>{props.pageTitle}</title>
        <meta name="description" content={props.pageDescription} />
        <meta name="keywords" content={props.pageKeywords} />
      </Helmet>
      {children}
    </div>
  );
}
