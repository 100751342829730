import OrderConstant from "../constants/OrderConstant";

export const getInfo = (item) => {
    const volumeArray = OrderConstant.VOLUME_ARRAY;
    const trashArray = OrderConstant.TRASH_ARRAY
    const takArray = OrderConstant.TAK_ARRAY;
    const unbuildArray = OrderConstant.UNBUILD_ARRAY;
    const packArray = OrderConstant.PACK_ARRAY;
    const liftTypes = OrderConstant.LIFT_TYPES;
    
    const prr_types =  OrderConstant.PRR_TYPE;
    let arr = [];
    arr.push({ text: volumeArray[item.volume_car].text });
    arr.push( { text: `${item.movers} грузчика` });

    arr.push({ text: prr_types[item.prr_types].text });
    if(item.moving_types != 4) {
        if(item.is_hard_work) arr.push({ text: takArray[item.tak_value].text });
        if(item.is_assembly_disassembly)arr.push({ text: unbuildArray[item.unbuild_value].text });
        if(item.additional_work)arr.push({ text: packArray[item.pack_value].text });
    } else {
        arr.push({ text: trashArray[item.trash_value].text });
    }
    
    return arr;
}

export const getTextInfo = (item) => {
    const volumeArray = OrderConstant.VOLUME_ARRAY;
    const trashArray = OrderConstant.TRASH_ARRAY
    const takArray = OrderConstant.TAK_ARRAY;
    const unbuildArray = OrderConstant.UNBUILD_ARRAY;
    const packArray = OrderConstant.PACK_ARRAY;
    const liftTypes = OrderConstant.LIFT_TYPES;
    
    const prr_types =  OrderConstant.PRR_TYPE;
    let arr = [];

    arr.push( `Тип: ${OrderConstant.MOVING_TYPE[item.moving_types].text}`  );
    arr.push(  volumeArray[item.volume_car].text );
    arr.push(  `${item.movers} грузчика`  );

    arr.push(  prr_types[item.prr_types].text );
    if(item.moving_types != 4) {
        if(item.is_hard_work) arr.push( takArray[item.tak_value].text );
        if(item.is_assembly_disassembly)arr.push(  unbuildArray[item.unbuild_value].text  );
        if(item.additional_work)arr.push(  packArray[item.pack_value].text );
    } else {
        arr.push(  trashArray[item.trash_value].text  );
    }

   
    arr.push( `Откуда: ${ item.address_a}`  );
    arr.push( `Лифт A: ${OrderConstant.LIFT_TYPES[item.elevator_type_a].text}`  );
    arr.push( `Этаж A: ${item.floor_a ? item.floor_a : "Нет"}`  );

    arr.push( `Куда: ${ item.address_b}`  );
    arr.push( `Лифт B: ${OrderConstant.LIFT_TYPES[item.elevator_type_b].text}`  );
    arr.push( `Этаж B: ${item.floor_b ? item.floor_b : "Нет"}`  );

    arr.push( `Машина: ${OrderConstant.VOLUME_ARRAY[item.volume_car].text}`  );
    arr.push( `Грузчики: ${item.movers}`  );
    arr.push( `Комментарий заказчика: ${item.text}`  );

    for(var i = 0; i < arr.length; i++){
        arr[i] = arr[i].replace(/ /g, "%20");
    }
    return arr.join("%0A");
}
