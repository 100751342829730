import React from "react";

import { ReactComponent as SvgMenu } from "../../../assets/images/ui/menu.svg";

import useDeclination from "../../../hooks/useDeclination";

import "./UiBoardTitle.scss";

export default function UiBoardTitle(props) {
  return (
    <div className={"board-title" + " " + props.color}>
      <div className="board-title-info">
        <h3>{props.title}</h3>
        <p>
          {props.num}{" "}
          {useDeclination(props.num, ["сделка", "сделки", "сделок"])}
          {props.sum > 0 ?
          (" на " + props.sum + " ₽")
          : null
        }
        </p>
      </div>
      {/*
      <button className="board-title-button" onClick={props.onClick}>
        <SvgMenu className="board-title-button-icon" />
      </button>
      */}
    </div>
  );
}
