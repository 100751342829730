import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { ReactComponent as SvgAvatar } from "../../assets/images/menu/avatar.svg";
import { ReactComponent as SvgBox } from "../../assets/images/menu/box.svg";
import { ReactComponent as SvgRating } from "../../assets/images/menu/rating.svg";
import { ReactComponent as SvgCase } from "../../assets/images/menu/case.svg";
import { ReactComponent as SvgSetting } from "../../assets/images/menu/setting.svg";
import { ReactComponent as SvgStatistic } from "../../assets/images/menu/statistic.svg";
import { ReactComponent as SvgTable } from "../../assets/images/menu/table.svg";

import "./UiMenu.scss";

function UiMenu() {
  let location = useLocation();

  return (
    <div className="menu-left">
      <div className="menu-left-list">
        <Link
          className={
            "menu-left-item" + (location.pathname === "/" ? " active" : "")
          }
          to="/"
        >
          <div className="menu-left-item-wrap">
            <SvgCase className="menu-left-item-icon" />
          </div>
          <span>Сделки</span>
        </Link>
        <Link
          className={
            "menu-left-item" +
            (location.pathname === "/clients" ? " active" : "")
          }
          to="/clients"
        >
          <div className="menu-left-item-wrap">
            <SvgAvatar className="menu-left-item-icon" />
          </div>
          <span>Клиенты</span>
        </Link>
        <Link
          className={
            "menu-left-item" + (location.pathname === "/orgs" ? " active" : "")
          }
          to="/orgs"
        >
          <div className="menu-left-item-wrap">
            <SvgTable className="menu-left-item-icon" />
          </div>
          <span>Бригады</span>
        </Link>
        <Link
          className={
            "menu-left-item" + (location.pathname === "/archive" ? " active" : "")
          }
          to="/archive"
        >
          <div className="menu-left-item-wrap">
            <SvgBox className="menu-left-item-icon" />
          </div>
          <span>Архив заказов</span>
        </Link>

        <Link
          className={
            "menu-left-item" + (location.pathname === "/clients-rating" ? " active" : "")
          }
          to="/clients-rating"
        >
          <div className="menu-left-item-wrap">
            <SvgRating className="menu-left-item-icon" />
          </div>
          <span>Отзывы</span>
        </Link>

        <Link
          className={
            "menu-left-item" + (location.pathname === "/setting" ? " active" : "")
          }
          to="/setting"
        >
          <div className="menu-left-item-wrap">
            <SvgSetting className="menu-left-item-icon" />
          </div>
          <span>Настройки</span>
        </Link>
        <Link
          className={
            "menu-left-item" + (location.pathname === "/statistic" ? " active" : "")
          }
          to="/statistic"
        >
          <div className="menu-left-item-wrap">
            <SvgStatistic className="menu-left-item-icon" />
          </div>
          <span>Статистика</span>
        </Link>
      </div>
    </div>
  );
}

export default UiMenu;
