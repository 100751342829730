import React, { useState } from "react";

import { ReactComponent as SvgAngle } from "../../assets/images/ui/arrow-sort.svg";
import { ReactComponent as SvgDelete } from "../../assets/images/ui/delete.svg";
import { ReactComponent as SvgEdit } from "../../assets/images/ui/pencil.svg";
import { ReactComponent as SvgOpen } from "../../assets/images/ui/search.svg";

import Env from "../../services/Env";


import "./UiTableAdaptiveCard.scss";

const UiTableAdaptiveCard = (props) => {
  const [last, setLast] = useState(null);

  if (props.cols.length > 0) {
    var cols = props.cols.map((item, index) => {
      return (
        <div
          className={
            "table-card-col" +
            (item.color === "blue" ? " blue" : "") +
            (item.color === "red" ? " red" : "") +
            (item.statusInfo ? (item.status ? " blue" : " red") : "") +
            (item.strong ? " strong" : "")
          }
          key={index}
          onClick={item.onClick ? item.onClick : null}
        >
          <div className="table-card-col-inner" onClick={() => {
            if (item.sort) {
              props.onFilter(last == index ? `-${index}` : index);
              setLast(last == index ? null : index);
            }

          }}>

            {item.image ? (
             <img src={Env.PUBLIC_URL + item.image} style={{width: "100px"}} />
            ) : null}

            {item.button ? (
              <button
                className="table-card-button"
                onClick={item.openClick ? item.openClick : null}
              >
                {item.button === "edit" ? (
                  item.editText ? (
                    <span>{item.editText}</span>
                  ) : (
                    <SvgEdit className="table-card-button-icon" />
                  )
                ) : null}
                {item.button === "open" ? (
                  <SvgOpen className="table-card-button-icon" />
                ) : null}
                {item.button === "delete" ? (
                  <SvgDelete className="table-card-button-icon" />
                ) : null}
              </button>
            ) : null}

            {item.title && item.title !== "" ? (
              <p
                className={"table-card-title"}
                onClick={props.textLink ? item.linkClick : null}
              >
                {item.title}
              </p>
            ) : null}
            {item.sort ? <SvgAngle className="table-card-sort" /> : null}
          </div>
          {item.text && item.text !== "" ? (
            <span className={item.textLink ? "link" : ""} onClick={item.linkClick} >{item.text}</span>
          ) : null}
          {item.statusInfo ? <span className={
            (item.statusColor === "blue" ? " blue" : "") +
            (item.statusColor === "green" ? " green" : "")
          }
          >{item.statusInfo}</span> : null}
        </div>
      );
    });
  }

  return (
    <div
      className={
        "table-card ui-grid" +
        (props.grid ? " grid-" + props.grid : "") +
        (props.isTitle ? " title" : "")
      }
    >
      {props.cols ? cols : "-"}
    </div>
  );
};

export default UiTableAdaptiveCard;
