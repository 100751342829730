import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getPrice as getPriceAction,
  updateByKey as updateByKeyAction
} from "../../redux/modules/settings";

import OrderConstant from "../../constants/OrderConstant";


import UiPageText from "../../components/text/UiPageText";
import UiInputText from "../../components/inputs/UiInputText";

import "./SettingStyle.scss";


function SettingPage(props) {
  const volumeArray = OrderConstant.VOLUME_ARRAY;
  const trashArray = OrderConstant.TRASH_ARRAY;
  const takArray = OrderConstant.TAK_ARRAY;
  const unbuildArray = OrderConstant.UNBUILD_ARRAY;
  const packArray = OrderConstant.PACK_ARRAY;
  const liftTypes = OrderConstant.LIFT_TYPES;
  const moving_types = OrderConstant.MOVING_TYPE;
  const prr_types = OrderConstant.PRR_TYPE;

  useEffect(() => {
    if (props.login.user) props.getPrice();
  }, [props.login.user]);

  let price_volume_input = props.settings.pricesObjects.volume_car.map((item, index) => {
    return (
      <UiInputText
        key={index}
        placeholder={props.settings.pricesObjects.volume_car[index].value}
        label={`Объем машины ${volumeArray[index].text}`}
        onBlur={(val) => {
          props.updateByKey(`volume_car_price_${index}`, val);
        }}
      />
    )
  })
  let price_movers_input = props.settings.pricesObjects.movers.map((item, index) => {
    return (
      <UiInputText
        key={index}
        placeholder={props.settings.pricesObjects.movers[index].value}
        label={`Грузчики - ${index} чел`}
        onBlur={(val) => {
          props.updateByKey(`movers_price_${index}`, val);
        }}
      />
    )
  })

  let prr_types_input = props.settings.pricesObjects.prr_types.map((item, index) => {
    return (
      <UiInputText
        key={index}
        placeholder={props.settings.pricesObjects.prr_types[index].value}
        label={`ПРР - ${prr_types[index].text}`}
        onBlur={(val) => {
          props.updateByKey(`prr_types_price_${index}`, val);
        }}
      />
    )
  })

  let trash_value_input = props.settings.pricesObjects.trash_value.map((item, index) => {
    return (
      <UiInputText
        key={index}
        placeholder={props.settings.pricesObjects.trash_value[index].value}
        label={`Мусор - ${trashArray[index].text}`}
        onBlur={(val) => {
          props.updateByKey(`trash_value_price_${index}`, val);
        }}
      />
    )
  })
  let tak_value_input = props.settings.pricesObjects.tak_value.map((item, index) => {
    return (
      <UiInputText
        key={index}
        placeholder={props.settings.pricesObjects.tak_value[index].value}
        label={`Такелаж - ${takArray[index].text}`}
        onBlur={(val) => {
          props.updateByKey(`tak_value_price_${index}`, val);
        }}
      />
    )
  })
  let moving_types_input = props.settings.pricesObjects.moving_types.map((item, index) => {
    return (
      <UiInputText
        key={index}
        placeholder={props.settings.pricesObjects.moving_types[index].value}
        label={`Тип - ${moving_types[index].text}`}
        onBlur={(val) => {
          props.updateByKey(`moving_types_price_${index}`, val);
        }}
      />
    )
  })
  let unbuild_value_input = props.settings.pricesObjects.unbuild_value.map((item, index) => {
    return (
      <UiInputText
        key={index}
        placeholder={props.settings.pricesObjects.unbuild_value[index].value}
        label={`Сборка/разборка - ${unbuildArray[index].text}`}
        onBlur={(val) => {
          props.updateByKey(`unbuild_value_price_${index}`, val);
        }}
      />
    )
  })
  let pack_value_input = props.settings.pricesObjects.pack_value.map((item, index) => {
    return (
      <UiInputText
        key={index}
        placeholder={props.settings.pricesObjects.pack_value[index].value}
        label={`Тип - ${packArray[index].text}`}
        onBlur={(val) => {
          props.updateByKey(`pack_value_price_${index}`, val);
        }}
      />
    )
  })

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки" />
        </div>
      </section>
      {console.log(props.settings.pricesObjects)}
      <section className="page-content">
        <p>Настройки цены</p>
        <div>
          {price_volume_input}
        </div>
        <div>
          {price_movers_input}
        </div>

        <div>
          {props.settings.pricesObjects.movers_additional ?
            <UiInputText
              placeholder={props.settings.pricesObjects.movers_additional.value}
              label={`Цена за доп. грузчика`}
              onBlur={(val) => {
                props.updateByKey(`movers_additional_price`, val);
              }}
            /> : null}
          {props.settings.pricesObjects.movers_additional ?
            <UiInputText
              placeholder={props.settings.pricesObjects.distance_km.value}
              label={`Цена за км пути`}
              onBlur={(val) => {
                props.updateByKey(`distance_km_price`, val);
              }}
            /> : null}
          {props.settings.pricesObjects.movers_additional ?
            <UiInputText
              placeholder={props.settings.pricesObjects.distance_less_25_km.value}
              label={`Цена за км пути если расстояние меньше 25 км`}
              onBlur={(val) => {
                props.updateByKey(`distance_less_25_km_price`, val);
              }}
            /> : null}
          {props.settings.pricesObjects.movers_additional ?
            <UiInputText
              placeholder={props.settings.pricesObjects.distance_then_25_km.value}
              label={`Цена за км пути если расстояние больше 25 км`}
              onBlur={(val) => {
                props.updateByKey(`distance_then_25_km_price`, val);
              }}
            /> : null}


        </div>
        <div>
          {prr_types_input}
          {trash_value_input}
          {tak_value_input}
          {moving_types_input}
          {unbuild_value_input}
          {pack_value_input}
        </div>



      </section>
    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getPrice: getPriceAction,
  updateByKey: updateByKeyAction
})(SettingPage);
