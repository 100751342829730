import ENV from './Env.js';
import ApiConstant from '../constants/ApiConstan.js';



export const storeOrder = (_data) => {
    console.log(_data);
    
    return fetch(`${ENV.API_URL}/deal/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
 

export const getMyOrder = (_data) => {
    return fetch(`${ENV.API_URL}/deal/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const getAllOrders = (_data) => {
    return fetch(`${ENV.API_URL}/deal/all`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
export const getFilteredOrders = (_data) => {
    return fetch(`${ENV.API_URL}/deal/filter`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const getOrdersByUser = (_data) => {
    return fetch(`${ENV.API_URL}/deal/show/user`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const updateUserOrder = (_data) => {
    return fetch(`${ENV.API_URL}/deal/update/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const doneUserOrder = (_data) => {
    return fetch(`${ENV.API_URL}/deal/done/${_data.deal_id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
