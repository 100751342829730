import React, { useState } from "react";

import "./UiBoardCard.scss";

export default function UiBoardCard(props) {
  if (props.members.length > 0) {
    var membersList = props.members.map((item, index) => {
      return (
        <div key={index} className="board-card-member">
          <p>{item.name}</p>
        </div>
      );
    });
  }

  return (
    <div className="board-card" onClick={props.onClick}>
      <div className="board-card-title">
        <h3>{props.title}</h3>
        <p>{props.time}</p>
      </div>
      {props.members ? (
        <div className="board-card-members">{membersList}</div>
      ) : null}
      <div className="board-card-info">
        {Array.isArray(props.text) ? 
         props.text.map((item,index) =>{
          return (
            <p key={index}>{item}</p>
          )})
         : 
        <p>{props.text}</p>
        }
      </div>
     
    </div>
  );
}
