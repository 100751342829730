import React, { useEffect, useState } from "react";
import OrderConstant from "../../constants/OrderConstant";

import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { replaceTags } from "../../utilities/logs";
import { getTextInfo } from "../../utilities/order";
import { inArrayValueByKey } from "../../utilities/array";

import { ReactComponent as SvgCalendar } from "../../assets/images/deal/calendar.svg";
import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";
import { ReactComponent as SvgInfo } from "../../assets/images/deal/info.svg";
import { ReactComponent as SvgCube } from "../../assets/images/deal/cube.svg";
import { ReactComponent as SvgPeople } from "../../assets/images/deal/people.svg";

import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalDeal.scss";
import UiTextArea from "../inputs/UiTextArea";
import UiSendMsg from "../bars/UiSendMsg";
import UiCardMsg from "../cards/UiCardMsg";
import UiButtonColor from "../button/UiButtonColor";
import Env from "../../services/Env";
import UiCardUser from "../cards/UiCardUser";
import UiInputSearch from "../inputs/UiInputSearch";

const UiDealTabButton = (props) => {
  return (
    <button
      className={
        "modal-deal-about-button" +
        " " +
        props.color +
        (props.active ? " active" : "")
      }
      onClick={props.onClick}
    >
      {props.icon == "info" ? (
        <SvgInfo className="modal-deal-about-button-icon" />
      ) : props.icon == "calendar" ? (
        <SvgCalendar className="modal-deal-about-button-icon" />
      ) : props.icon == "people" ? (
        <SvgPeople className="modal-deal-about-button-icon" />
      ) : props.icon == "cube" ? (
        <SvgCube className="modal-deal-about-button-icon" />
      ) : null}
    </button>
  );
};

function UiModalDeal(props) {
  const [tab, setTab] = useState(0);
  const [workerSearch, setWorkerSearch] = useState("");

  const tabButtonsList = [
    { color: "teal", icon: "info" },
    { color: "primary", icon: "people" },
    { color: "amber", icon: "cube" },
  ];

  let tabButtons = tabButtonsList.map((item, index) => {
    return (
      <UiDealTabButton
        key={index}
        active={tab == index}
        color={item.color}
        icon={item.icon}
        onClick={() => setTab(index)}
      />
    );
  });

  let logs = (props.logs ? props.logs : []).map((item, index) => {
    return (
      <UiCardMsg
        key={index}
        text={replaceTags(item.text, item.author_id  )}
        color={"#0091ea"}
        sender={"ℹ"}
        date={formatDateDMY(item.created_at)}
        time={formatDateHM(item.created_at)}
      />
    );
  });

  let msgs = (props.comments ? props.comments : []).map((item, index) => {
    return (
      <UiCardMsg
        key={index}
        sender={item.email}
        text={item.text}
        date={formatDateDMY(item.created_at)}
        time={formatDateHM(item.created_at)}
      />
    );
  });

  let photos = (
    props.deal ? (props.deal.photos ? props.deal.photos.split(",") : []) : []
  ).map((item, index) => {
    return (
      <a href={Env.PUBLIC_URL + item} target="_blank">
        <img src={Env.PUBLIC_URL + item} style={{ width: "100px" }} />
      </a>
    );
  });

  if (props.deal) {

    let workers = (props.workers ? props.workers : []).map((item, index) => {
      if (workerSearch == "" || item.name.includes(workerSearch) || item.phone.includes(workerSearch)) {
        return (
          <UiCardUser
            key={index}
            id={item.id}
            href={`https://wa.me/${item.phone}?text=${getTextInfo(props.deal)}`}
            name={item.name}
            phone={item.phone}
            rating={item.rating}
            userDeal={item.deal}
            deal={props.deal}
            onAddWorker={() => props.onAddWorker(item.id)}
          /> 
        );
      }
    });
    return (
      <UiModal name="modal" show={props.modalActive}>
        <div className="modal-deal">
          <div className="modal-deal-wrap">
            {/**
             * modal left side - static
             */}
            <div className="modal-deal-about">
              {/**
               * modal title
               */}
              <div className="modal-deal-about-title">
                <div className="modal-deal-about-title-wrap">
                  <h3>Заказ #{props.deal ? props.deal.id : null}</h3>
                  <p>
                    Статус:{" "}
                    {props.deal
                      ? OrderConstant.ORDER_TYPES_NAMES[props.deal.status]
                      : null}
                  </p>
                </div>
                <div className="modal-deal-about-buttons">{tabButtons}</div>
              </div>
              {/**
               * modal notifications + msgs
               */}
              <div className="modal-deal-notes">
                <div className="modal-deal-notes-list-wrap">
                  <div className="modal-deal-notes-list">
                    {logs}
                    {msgs}
                  </div>
                </div>
                <div className="modal-deal-notes-form">
                  <UiSendMsg
                    onAddPress={(text) =>
                      props.onAddComment({ deal_id: props.deal.id, text: text })
                    }
                  />
                </div>
              </div>
            </div>
            {/**
             * modal right side - tabs
             */}
            <div className="modal-deal-info-wrap">
              {/** close button */}
              <button
                className="modal-deal-info-close"
                onClick={() => props.modalClose()}
              >
                <SvgClose className="modal-deal-info-close-icon" />
              </button>
              {/**
               * TABS
               */}
              {/**
               * tab 0 - deal info
               */}
              {tab == 0 ? (
                <div className="modal-deal-info">
                  <h3>Описание заказа</h3>
                  <div className="modal-deal-info-form">
                    <div className="modal-deal-info-static">
                      <p>
                        <b>Тип:</b>{" "}
                        {
                          OrderConstant.MOVING_TYPE[props.deal.moving_types]
                            .text
                        }{" "}
                      </p>
                      <br />
                      <p>
                        <b>Откуда:</b> {props.deal.address_a}{" "}
                      </p>
                      <p>
                        <b>Лифт A:</b>{" "}
                        {
                          OrderConstant.LIFT_TYPES[props.deal.elevator_type_a]
                            .text
                        }{" "}
                      </p>
                      <p>
                        <b>Этаж A:</b>{" "}
                        {props.deal.floor_a ? props.deal.floor_a : "Нет"}{" "}
                      </p>
                      <br />
                      <p>
                        <b>Куда:</b> {props.deal.address_b}{" "}
                      </p>
                      <p>
                        <b>Лифт B:</b>{" "}
                        {
                          OrderConstant.LIFT_TYPES[props.deal.elevator_type_b]
                            .text
                        }{" "}
                      </p>
                      <p>
                        <b>Этаж B:</b>{" "}
                        {props.deal.floor_b ? props.deal.floor_b : "Нет"}{" "}
                      </p>
                      <br />
                      {props.deal.moving_types != 4 ? (
                        <div>
                          {props.deal.is_hard_work ? (
                            <p>
                              <b>Такелажные работы:</b>{" "}
                              {
                                OrderConstant.TAK_ARRAY[props.deal.tak_value]
                                  .text
                              }{" "}
                            </p>
                          ) : null}
                          {props.deal.is_assembly_disassembly ? (
                            <p>
                              <b>Сборка и разборка:</b>{" "}
                              {
                                OrderConstant.UNBUILD_ARRAY[
                                  props.deal.unbuild_value
                                ].text
                              }{" "}
                            </p>
                          ) : null}
                          {props.deal.is_package ? (
                            <p>
                              <b>Упаковка и пленка:</b>{" "}
                              {
                                OrderConstant.PACK_ARRAY[props.deal.pack_value]
                                  .text
                              }{" "}
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <p>
                          <b>Объем мусора:</b>{" "}
                          {
                            OrderConstant.TRASH_ARRAY[props.deal.trash_value]
                              .text
                          }{" "}
                        </p>
                      )}
                      <p>
                        <b>ПРР:</b>{" "}
                        {OrderConstant.PRR_TYPE[props.deal.prr_types].text}{" "}
                      </p>
                      <p>
                        <b>Машина:</b>{" "}
                        {OrderConstant.VOLUME_ARRAY[props.deal.volume_car].text}{" "}
                      </p>
                      <p>
                        <b>Грузчики:</b> {props.deal.movers} чел.
                      </p> 
                      <br />
                      <p>
                        <b>Заказчик звонить:</b>{" "}
                        {props.deal.phone ? (
                          <a
                            className="modal-deal-button-call primary"
                            href={`tel:+${props.deal.phone}`}
                          >
                            +{props.deal.phone}
                          </a>
                        ) : (
                          <p>нет телефона</p>
                        )}
                      </p>
                      <br />
                      <p>
                        <b>WhatsApp:</b>{" "}
                        {props.deal.phone ? (
                          <a
                            className="modal-deal-button-call green"
                            target={"_blank"}
                            href={`https://app.pachca.com/whatsapp/send?phone=${props.deal.phone}&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!`}
                          >
                            Написать (только с телефона)
                          </a>
                        ) : (
                          <p>нет телефона</p>
                        )}
                      </p>

                      <br />
                      <p>
                        <b>Дата работы:</b> {props.deal.date}{" "}
                      </p>
                      <p>
                        <b>Время работы:</b> {props.deal.time}{" "}
                      </p>
                      <br />
                      <p>
                        <b>Дистанция:</b> {props.deal.distance} км{" "}
                      </p>
                      <br />
                     
                      <p>
                      

                        <UiInputText 
                          placeholder={props.deal.text} 
                          label="Комментарий"   
                          onBlur={(val) => {
                            props.onEditText(val)
                          }}
                        />
                          
                           
                      </p>
                      <br />
                      <p>
                       
                        <UiInputText 
                          placeholder={props.deal.price} 
                          label="Цена (рубли)" 
                          type="number"
                          onBlur={(val) => {
                            props.onEditPrice(val)
                          }}
                        />
                        
                      </p>
                      <br />
                      {photos}
                    </div>
                  </div>
                </div>
              ) : null}
              {/**
               * tab 1 - contact card
               */}
              {tab == 1 ? (
                <div className="modal-deal-info">
                  <h3>Работники</h3>
                  <div className="modal-deal-info-form">
                    <p>Назначен:</p>
                    <p><b>{inArrayValueByKey(props.workers, "id", props.deal.worker_id) ? inArrayValueByKey(props.workers, "id", props.deal.worker_id).name : "нет назначенного работника"}</b></p>
                    <div className="modal-deal-info-workers">
                      <UiInputSearch placeholder="Найти работника" onChange={(val) => setWorkerSearch(val)} />
                      {workers}
                    </div>
                  </div>
                </div>
              ) : null}
              {/**
               * tab 2 - note
               */}
              {/*tab == 2 ? (
                <div className="modal-deal-info">
                  <h3>Заметка</h3>
                  <div className="modal-deal-info-form">
                    {//<UiTextArea placeholder="Введите заметку.." />}
                  </div>
                </div>
              ) : null*/}
              {/**
               * tab 2 - more info (app info)
               */}
              {tab == 2 ? (
                <div className="modal-deal-info">
                  <div className="modal-deal-info-top">
                    <UiInputText disabled={true} value="Заголовок сделки" />
                  </div>
                  {/*<p>Василий Солодухин 21 июня в 21:00</p>*/}
                  <div className="modal-deal-info-form">
                    <UiInputText disabled={true} label="Стоимость" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </UiModal>
    );
  } else {
    return null;
  }
}

export default UiModalDeal;
