import React, { useEffect, useState } from "react";
import { addFile } from "../../services/Storage";

import OrderConstant from "../../constants/OrderConstant";

import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { inArray, inArrayValue, unCheckInArray } from "../../utilities/array";
import { calculateOrder } from "../../utilities/calculate";

import { ReactComponent as SvgCalendar } from "../../assets/images/deal/calendar.svg";
import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";
import { ReactComponent as SvgInfo } from "../../assets/images/deal/info.svg";
import { ReactComponent as SvgCube } from "../../assets/images/deal/cube.svg";
import { ReactComponent as SvgPeople } from "../../assets/images/deal/people.svg";

import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalDeal.scss";
import UiDropDown from "../inputs/UiDropDown";
import UiTextArea from "../inputs/UiTextArea";
import UiSwitch from "../switch/UiSwitch";
import UiCardAddPhoto from "../cards/UiCardAddPhoto";
import UiTextInputMasked from "../inputs/UiTextInputMasked";
import UiButtonColor from "../button/UiButtonColor";
import Env from "../../services/Env";

function UiModalDealAdd(props) {
  const volumeArray = OrderConstant.VOLUME_ARRAY;
  const trashArray = OrderConstant.TRASH_ARRAY;
  const takArray = OrderConstant.TAK_ARRAY;
  const unbuildArray = OrderConstant.UNBUILD_ARRAY;
  const packArray = OrderConstant.PACK_ARRAY;
  const liftTypes = OrderConstant.LIFT_TYPES;
  const moving_types = OrderConstant.MOVING_TYPE;
  const prr_types = OrderConstant.PRR_TYPE;

  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [load, setLoad] = useState(false);

  const [order, setOrder] = useState({
    type: 0,
    order_type: 0,
    order_name: null,
    user_id: 0,
    user_name: null,

    //step1
    address_a: "",
    address_b: "",
    elevator_type_a: 0,
    elevator_type_b: 0,
    address_a_city: null,
    address_b_city: null,

    floor_a: "",
    floor_b: "",
    date: new Date(),
    time: new Date(),
    date_dmy: null,
    time_hm: null,
    //step2
    moving_types: 0,
    prr_types: 0,
    volume_car: 0,
    movers: 0,
    additional_work: [],
    tak_value: 0,
    unbuild_value: 0,
    pack_value: 0,
    trash_value: 0,
    photos: [],
    text: "",

    distance: 0,
    price: 0,
  });

  function uploadList(file) {
    setLoad(true);
    addFile(file, 2).then((res) => {
      console.log(res);
      setLoad(false);
      if (res.success) {
        setImage(res.response);
        setImageUrl(Env.PUBLIC_URL + res.response);
      }
    });
  }

  function init() {
    var DELIVERY_TARIFF = 20,
      MINIMUM_COST = 500,
      myMap = new window.ymaps.Map("map", {
        center: [60.906882, 30.067233],
        zoom: 9,
        controls: [],
      }),
      routePanelControl = new window.ymaps.control.RoutePanel({
        options: {
          showHeader: true,
          title: "Расчёт доставки",
        },
      }),
      zoomControl = new window.ymaps.control.ZoomControl({
        options: {
          size: "small",
          float: "none",
          position: {
            bottom: 145,
            right: 10,
          },
        },
      });
    routePanelControl.routePanel.options.set({
      types: {
        auto: true,
      },
    });
    routePanelControl.routePanel.state.set({
      fromEnabled: false,
      from: order.address_a,
      to: order.address_b,
      type: "auto",
    });
    myMap.controls.add(routePanelControl).add(zoomControl);
    routePanelControl.routePanel.getRouteAsync().then((route) => {
      route.model.setParams(
        {
          results: 1,
        },
        true
      );
      route.model.events.add("requestsuccess",  () => {
        var activeRoute = route.getActiveRoute();
        if (activeRoute) {
          var length = route.getActiveRoute().properties.get("distance");
          calculate(Math.round(length.value / 1000));
        }
      });
    });

    function calculate(routeLength) {
      console.log("distance", routeLength);
      updateOrderObj("distance", routeLength);
    }
  }

  useEffect(() => {
    window.ymaps.ready(init);
  }, [order.address_a, order.address_b]);

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = order;
    if (val != "price") {
      _obj["price"] = calculateOrder(props.prices , order);
    }
    _obj[key] = val;
    setOrder({ ..._obj });
  }

  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-deal">
        <div className="modal-deal-wrap">
          {/**
           * modal left side - static
           */}
          <div className="modal-deal-about add">
            {/**
             * modal title
             */}
            <div className="modal-deal-about-title">
              <div className="modal-deal-about-title-wrap">
                <h3>Создать заказ</h3>
              </div>
            </div>
            {/**
             * modal notifications + msgs
             */}
            <div className="modal-deal-notes">
              <div className="modal-deal-notes-list-wrap">
                <div className="modal-deal-info">

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Название заказа:"
                      placeholder="Название заказа (не обязательно)"
                      onChange={(val) => {
                        updateOrderObj("order_name", val)
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiTextInputMasked
                      label="Телефон заказчика:"
                      placeholder="Введите Телефон.."
                      onChange={(val) => {
                        var str = val.replace(/[^0-9]/g, '');
                        updateOrderObj("user_phone", str)
                      }}
                      onBlur={(val) => { 
                        var str = val.replace(/[^0-9]/g, '');
                        props.findUserEvent(str).then((res) => {
                          if(res)  updateOrderObj("user_name", res.name)
                        })
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Имя заказчика:"
                      placeholder="Введите Имя заказчика.."
                      value={order.user_name}
                      onChange={(val) => { 
                        updateOrderObj("user_name", val)
                      }}
                    />
                  </div>


                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Адрес пункта А:"
                      placeholder="Введите адрес.."
                      onChange={(val) => updateOrderObj("address_a", val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiDropDown
                      label="Наличие лифта в пункте А:"
                      items={liftTypes}
                      onChange={(val) => updateOrderObj("elevator_type_a", val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Этаж"
                      placeholder="Этаж.."
                      onChange={(val) => updateOrderObj("floor_a", val)}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Адрес пункта B:"
                      placeholder="Введите адрес.."
                      onChange={(val) => updateOrderObj("address_b", val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiDropDown
                      label="Наличие лифта в пункте B:"
                      items={liftTypes}
                      onChange={(val) => updateOrderObj("elevator_type_b", val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Этаж"
                      placeholder="Этаж.."
                      onChange={(val) => updateOrderObj("floor_b", val)}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Дата заказа"
                      type={"date"}
                      onChange={(val) => {
                        console.log(val);
                        updateOrderObj("date", val);
                        updateOrderObj("date_dmy", formatDateDMY(val));
                      }}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Время заказа"
                      type={"time"}
                      onChange={(val) => {
                        updateOrderObj("time", val);
                        updateOrderObj("time_hm", formatDateHM(val));
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiDropDown
                      label="Переезд:"
                      items={moving_types}
                      onChange={(val) => {
                        updateOrderObj("moving_types", val);
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <p>
                      <b>Дистанция:</b> {order.distance} км
                    </p>
                    <br />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Цена (рубли):"
                      value={order.price}
                      onChange={(val) => {
                        updateOrderObj("price", val);
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiButtonColor
                      color="primary"
                      text="Создать"
                      small={false}
                      onClick={() => {
                        if (
                          order.address_a != "" &&
                          order.address_b != "" &&
                          order.date_dmy != null &&
                          order.time_hm != null
                        ) {
                          if (order.volume_car != "" && order.movers != "") {
                            let _order = order;
                            //_order.price = calculateOrder(order);
                            props.onCreate(_order);
                          } else {
                            alert(
                              "Заполните объем и коло-во грузчиков"
                            );
                          }
                        } else {
                          alert("Заполните поля адрес и дату");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**
           * modal right side - tabs
           */}
          <div className="modal-deal-info-wrap">
            {/** close button */}
            <button
              className="modal-deal-info-close"
              onClick={() => props.modalClose()}
            >
              <SvgClose className="modal-deal-info-close-icon" />
            </button>
            <div className="modal-deal-info">
              <h3>Услуги, грузчики и объем</h3>
              <div className="modal-deal-info-form">
                <UiDropDown
                  label="Погрузочно-разгрузочные работы:"
                  items={liftTypes}
                  onChange={(val) => updateOrderObj("prr_types", val)}
                />
                <UiDropDown
                  label="Объем машины, кубатура:"
                  items={volumeArray}
                  onChange={(val) => updateOrderObj("volume_car", val)}
                />
                <UiInputText
                  label="Необходимые грузчики"
                  placeholder="от 0 до 5 чел."
                  type={"number"}
                  minValue={0}
                  maxValue={5}
                  onChange={(val) => updateOrderObj("movers", val)}
                />
                {order.moving_types != 4 ? (
                  <div className="modal-deal-add-switch">
                    <UiSwitch
                      label="Такелажные работы"
                      onChange={(val) =>
                        updateOrderObj(
                          "additional_work",
                          unCheckInArray(order.additional_work, 0)
                        )
                      }
                    />
                    <UiSwitch
                      label="Сборка и разборка"
                      onChange={(val) =>
                        updateOrderObj(
                          "additional_work",
                          unCheckInArray(order.additional_work, 1)
                        )
                      }
                    />
                    <UiSwitch
                      label="Упаковка и пленка"
                      onChange={(val) =>
                        updateOrderObj(
                          "additional_work",
                          unCheckInArray(order.additional_work, 2)
                        )
                      }
                    />
                  </div>
                ) : null}
                <div>
                  {inArray(order.additional_work, 0) ? (
                    <UiDropDown
                      label={"Вид такелажа"}
                      placeholder="от 1.5 до 45 м3"
                      items={takArray}

                      onChange={(val) => updateOrderObj("tak_value", val)}
                    />
                  ) : null}
                  {inArray(order.additional_work, 1) ? (
                    <UiDropDown
                      label="Вид сборки и разборки"
                      placeholder="от 1.5 до 45 м3"
                      items={unbuildArray}
                      onChange={(val) => updateOrderObj("unbuild_value", val)}
                    />
                  ) : null}
                  {inArray(order.additional_work, 2) ? (
                    <UiDropDown
                      label="Вид упаковки"
                      placeholder=" "
                      items={packArray}
                      onChange={(val) => updateOrderObj("pack_value", val)}
                    />
                  ) : null}
                  {order.moving_types == 4 ? (
                    <UiDropDown
                      label="Объем мусора"
                      placeholder="от 1.5 до 45 м3"
                      items={trashArray}
                      onChange={(val) => updateOrderObj("trash_value", val)}
                    />
                  ) : null}
                </div>

                
                  <UiTextArea
                    label="Комментарий:"
                    placeholder="Введите комментарий.."
                    onChange={(val) => {
                      updateOrderObj("text", val);
                    }}
                  />
                
              </div>

              <div
                id="map"
                style={{ width: "350px", height: "300px", display: "none" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </UiModal>
  );
}

export default UiModalDealAdd;
