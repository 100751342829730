import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUsersList as getUsersListAction,
} from "../../redux/modules/users";
import { formatDateDMY, formatDateHM } from "../../utilities/date";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalDeal from "../../components/modals/UiModalDeal";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ClientsStyle.scss";

function ClientsPage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: true, title: "ID" },
    { sort: true, title: "Имя" },
    { sort: false, title: "Телефон" },
    { sort: false, title: "Ip Адрес" },
    { sort: false, title: "Дата регистрации" },
  ];
  const [tableListUsers, setTableListUsers] = useState([]);


  useEffect(() => {
    if (props.login.user) props.getUsersList();
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.users.usersList.map((item, index) => {
      console.log(item)
      arr.push([
        { title: clientsTitles[0].title, text: `#${item.id}` },
        { title: clientsTitles[1].title, text: item.name ? item.name : "Не указан" },
        {
          title: clientsTitles[2].title,
          text: `+${item.phone}`,
        },
        { title: clientsTitles[3].title, text: item.ip_address },
        { title: clientsTitles[4].title, text: `${formatDateDMY(item.created_at)} ${formatDateHM(item.created_at)}` },
      ])
    })
    setTableListUsers(arr)
  }, [props.users.usersList])

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Клиенты" />
        </div>
        <div className="home-info-button">
          {/*
          <UiButtonColor
            color="primary"
            text="Новый клиент"
            small={true}
            onClick={() => { }}
          />
        */}
        </div>
      </section>
      <section className="page-content">
        <UiInputSearch
          label="Список пользователей"
          placeholder="Поиск..."
          onChange={(val) => setSearchLine(val)}
        />
        <UiTableAdaptive titleList={clientsTitles} grid={5} tableList={tableListUsers} search={searchLine} />
      </section>
      {/* modal deal */}
      <UiModalDeal modalActive={false} />
    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  getUsersList: getUsersListAction,
})(ClientsPage);
