import React from "react";
import { isBrowser, isMobile } from "react-device-detect";

import useWindowDimensionsReturn from "../../hooks/useWindowDimensionsReturn";

import BgFull from "../../assets/icons/bg/BgFull";
import BgMobile from "../../assets/icons/bg/BgMobile";

import UiLogoTitle from "../../components/text/UiLogoTitle";

import "./UiHeaderBg.scss";

function UiHeaderBg(props) {
  const { height, width } = useWindowDimensionsReturn();

  return (
    <header className="header-wrap">
      <div
        className={
          "header-inner" + (props.keyboardActive ? " keyboard-active" : "")
        }
      >
        <UiLogoTitle />
      </div>
    </header>
  );
}

export default UiHeaderBg;
