import React, { useEffect, useState } from "react";

import "./UiSwitch.scss";

export default function UiSwitch(props) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (props.value != undefined) {
      setActive(props.value);
    }
  }, []);

  const setCheck = () => {
    let value = !active;
    setActive(value);
    props.onChange(value);
  };

  return (
    <button
      className={"switch" + (active ? " active" : "")}
      disabled={props.disabled}
      onClick={() => setCheck()}
    >
      <div className="switch-check"></div>
      {active ? (
        <span className="switch-label">{props.label}</span>
      ) : (
        <span className="switch-label">{props.label}</span>
      )}
    </button>
  );
}
