import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OrderConstant from "../../constants/OrderConstant";
import {
  getAllUserOrders as getAllUserOrdersAction,
  updateOrder as updateOrderAction,
  addOrder as addOrderAction,
  doneOrder as doneOrderAction
} from "../../redux/modules/order";
import {
  getDealComments as getDealCommentsAction,
  addComment as addCommentAction,
} from "../../redux/modules/comment";
import {
  getDealLogs as getDealLogsAction
} from "../../redux/modules/logs";
import {
  filterUser as filterUserAction,
  getWorkers as getWorkersAction,
} from "../../redux/modules/login";
import { 
  getUsersByPhone as getUsersByPhoneAction 
} from "../../redux/modules/users";
import {
  getPrice as getPriceAction,
} from "../../redux/modules/settings";

import UiBoard from "../../components/board/UiBoard";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalDealAdd from "../../components/modals/UiModalDealAdd";
import UiPageText from "../../components/text/UiPageText";

import "./HomeStyle.scss";

function HomePage(props) {
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);

  useEffect(() => { 
    if (props.login.user) {
      props.getAllUserOrders();
      props.getWorkers();
      props.getPrice();
    }
  }, [props.login.user]);

  useEffect(() => {

  }, [props.login.user]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Сделки" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="primary"
            text="Новый заказ"
            small={true}
            onClick={() => setModalDealAddActive(true)}
          />
        </div>
      </section>
      <section className="page-content">
        <UiBoard
          list={props.order ? props.order.ordersList : []}
          comments={props.comment.commentsList ? props.comment.commentsList : []}
          logs={props.logs.logsList ? props.logs.logsList : []}
          workers={props.login.usersWorker ? props.login.usersWorker : []}
          onChangeStatus={(item, status) => {
            let _item = item;
            _item.status = status;
            //done deal
            if (status == OrderConstant.ORDER_STATUS_DONE) {
              props.doneOrder({ deal_id: item.id, worker_id: item.worker_id, user_id: item.user_id })
            } else {
              props.updateOrder(_item, OrderConstant.UPDATE_INFO_NEW_STATUS)

            }

          }}
          onChangeDealWorker={(item, wid) => {
            let _item = item;
            _item.worker_id = wid;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_NEW_WORKER);
            props.getWorkers();
            props.getDealLogs(_item.id);
          }}
          onRequestDeal={(id) => {
            props.getDealComments(id);
            props.getDealLogs(id);
          }}
          onAddComment={(data) => props.addComment(data)}
          onChangeDealPrice={(item, price) => {
            let _item = item;
            _item.price = price;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_PRICE);
            props.getDealLogs(_item.id);
          }}
          onChangeDealText={(item, text) => {
            let _item = item;
            _item.text = text;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
            props.getDealLogs(_item.id);
          }}
        />
      </section>
      {/* modal deal add */}
      <UiModalDealAdd
        modalActive={modalDealAddACtive}
        modalClose={() => setModalDealAddActive(false)}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}
        onCreate={(data) => {
          props.addOrder(data).then(() => {
            setModalDealAddActive(false)
          })
        }}
      />
    </div>
  );
}

export default connect(({ order, login, comment, logs, user, settings }) => ({ order, login, comment, logs, user, settings  }), {
  getAllUserOrders: getAllUserOrdersAction,
  updateOrder: updateOrderAction,
  addOrder: addOrderAction,
  doneOrder: doneOrderAction,
  getDealComments: getDealCommentsAction,
  addComment: addCommentAction,
  getDealLogs: getDealLogsAction,
  filterUser: filterUserAction,
  getWorkers: getWorkersAction,
  getUsersByPhone: getUsersByPhoneAction,
  getPrice: getPriceAction,
})(HomePage);
