import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getFilteredUserOrders as getFilteredUserOrdersAction,
  addOrder as addOrderAction,
  doneOrder as doneOrderAction
} from "../../redux/modules/order";
import { formatDateDMY, formatDateHM } from "../../utilities/date";

import OrderConstant from "../../constants/OrderConstant";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalUser from "../../components/modals/UiModalUser";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ArchiveStyle.scss";

function ArchivePage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "ID" },
    { sort: true, title: "Телефон заказчика" },
    { sort: true, title: "Тип работы" },
    { sort: true, title: "Откуда" },
    { sort: true, title: "Куда" },
    { sort: true, title: "Кол-во грузчиков" },
    { sort: true, title: "Цена" },
    { sort: true, title: "Дата заказа" },
    { sort: true, title: "Дата создания заказа" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  const [modalActive, setModalActive] = useState(false);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    if (props.login.user) props.getFilteredUserOrders("status", OrderConstant.ORDER_STATUS_DONE );
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.order.ordersList.map((item, index) => {
      console.log(item)
      arr.push([
        {
          title: clientsTitles[0].title,
          text: "",
          button: "open",
          openClick: () => {
            setItemData(item)
            setModalActive(true)
          },
        },
        { title: clientsTitles[1].title, text: `#${item.id}` },
        { title: clientsTitles[2].title,  text: `+${item.user_phone}`},
        {
          title: clientsTitles[3].title,
          text: OrderConstant.MOVING_TYPE[item.moving_types].text,
        },
        {
          title: clientsTitles[4].title,
          text: item.address_a, 
        },
        {
          title: clientsTitles[5].title,
          text: item.address_b, 
        },
        {
          title: clientsTitles[6].title,
          text: item.movers, 
        },
        {
          title: clientsTitles[7].title,
          text: `${item.price} р`, 
        },
        { title: clientsTitles[8].title, text: `${formatDateDMY(item.date)} ${formatDateHM(item.time)}` },
        { title: clientsTitles[9].title, text: `${formatDateDMY(item.created_at)} ${formatDateHM(item.created_at)}` },
      ])
    })
    setTableListOrders(arr)
  }, [props.order.ordersList])

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Архив заказов" />
        </div>
        <div className="home-info-button">
        </div>
      </section>
      <section className="page-content">
        <UiInputSearch
          label="Список выполненных заказов"
          placeholder="Поиск..."
          onChange={(val) => setSearchLine(val)}
        />
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>
      {/* modal deal */}
   
    </div>
  );
}

export default connect(({ login, order }) => ({ login, order }), {
  getFilteredUserOrders: getFilteredUserOrdersAction,
  addOrder: addOrderAction,
  doneOrder: doneOrderAction
})(ArchivePage);
