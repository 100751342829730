export const inArray = (_arr, _val) => {
    let f = false;
    if(_arr ) _arr.map((item) => { if (item == _val) f = true })
 
    return f;
}

export const inArrayValue = (_arr, _val) => {
    let f = 0;
    _arr.map((item) => { if (item == _val) f = item })
   
    return f;
}

export const inArrayValueByKey = (_arr, _key, _val) => {
    let f = null;
    _arr.map((item) => { if (item[_key] == _val) f = item })
   
    return f;
}

export const unCheckInArray = (_arr, _val) => {
    let f = false;
    let _index = 0;
    _arr.map((item,index) => {
        if (item == _val) {
            f = true;
            _index = index;
        }
    })
    if(f) _arr.splice(_index, 1); else _arr.push(_val); 
 
    return _arr;
}
 
export const sumItemValue = (_arr, _key) => {
    let _sum = 0;
    _arr.map((item,index) => {
       _sum = _sum + item[_key];
    })
    return _sum;
}

 