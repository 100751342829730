
export const saveUser = (_user) => {
  localStorage.setItem("lucid_panel_user", JSON.stringify(_user) );
}

export const loadUser = (_user) => {
 let user = localStorage.getItem("lucid_panel_user");
 if(user != null && user != undefined) user = JSON.parse(user); 
 return user;
}

export const saveLocation = (_user) => {
  localStorage.setItem("lucid_panel_user_location", JSON.stringify(_user) );
}

export const loadLocation = (_user) => {
 let user = localStorage.getItem("lucid_panel_user_location");
 if(user != null && user != undefined) user = JSON.parse(user); 
 return user;
}


export const getUID = () => {
  let user = localStorage.getItem("lucid_panel_user");
  if(user != null && user != undefined) {
    user = JSON.parse(user); 
    return user.id;
  } else {
    return null;
  }
};


export const isAuthenticated = () => {
  if (localStorage.getItem("lucid_panel_user") == null) {
    return false;
  } else {
    return true;
  }
};

export const isOrg = () => {
  let user = localStorage.getItem("lucid_panel_user");
  if(user != null && user != undefined) {
    user = JSON.parse(user); 
    if (user.user_form_id == 1 || user.user_form_id == 2) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
 
};


export const logout = () => {
  if (localStorage.getItem("lucid_panel_user") != null) {
    localStorage.removeItem("lucid_panel_user");
  }
};
