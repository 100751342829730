import React, { useEffect, useState } from "react";
import { addFile, convertImages } from "../../services/Storage";


import { ReactComponent as SvgCalendar } from "../../assets/images/deal/calendar.svg";
import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";
import { ReactComponent as SvgInfo } from "../../assets/images/deal/info.svg";
import { ReactComponent as SvgCube } from "../../assets/images/deal/cube.svg";
import { ReactComponent as SvgPeople } from "../../assets/images/deal/people.svg";

import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalDeal.scss";
import "./UiModalUser.scss";
import UiTextArea from "../inputs/UiTextArea";
import UiDropDown from "../inputs/UiDropDown";
import UiTextInputMasked from "../inputs/UiTextInputMasked";
import UiCardAddPhoto from "../cards/UiCardAddPhoto";
import UiCardMsg from "../cards/UiCardMsg";

import UiButtonColor from "../button/UiButtonColor";
import Env from "../../services/Env";

function UiModalUser(props) {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [contact, setContact] = useState(null);
  const [comment, setComment] = useState(null);
  const [rating, setRating] = useState(null);

  const [paymentInfo, setPaymentInfo] = useState(null);
  const [fContact, setFContact] = useState(null);
  const [car, setCar] = useState(null);
  const [carSize, setCarSize] = useState(null);
  const [carCarrying, setCarCarrying] = useState(null);
  const [carVolume, setCarVolume] = useState(null);

  const [isConfirm, setConfirm] = useState(false);
  const [isWorker, setWorker] = useState(false);

  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [load, setLoad] = useState(false);

  function uploadList(file) {
    setLoad(true);
    addFile(file, 2).then((res) => {
      console.log(res);
      setLoad(false);
      if (res.success) {
        let _arr = images;
        _arr.unshift(res.response);
        setImages(_arr);
        setLoad(true)
        setTimeout(() => setLoad(false), 10)
      }
    });
  }

  useEffect(() => {
    if (props.data != null) {
      setName(props.data.name);
      setPhone(props.data.phone);
      setContact(props.data.contact);
      setImages(convertImages(props.data.images));
      setImageUrl(props.data.images ? Env.PUBLIC_URL + props.data.images : null);
      setComment(props.data.comment);
      setRating(props.data.rating);
      setPaymentInfo(props.data.payment_info);
      setFContact(props.data.fact_contact);
      setCar(props.data.car);
      setCarSize(props.data.car_size);
      setCarCarrying(props.data.car_carrying);
      setCarVolume(props.data.car_volume);
      setConfirm(props.data.is_confirm);
      setWorker(props.data.is_worker);
    } else {
      setName(null);
      setPhone(null);
      setContact(null);
      setImages([]);
      setComment(null);
      setImageUrl(null);
      setRating(null);
      setPaymentInfo(null);
      setFContact(null);
      setCar(null);
      setCarSize(null);
      setCarCarrying(null);
      setCarVolume(null);
      setConfirm(false);
    }
  }, [props.data]);

  var imagesList = images.map((item, index) => {
    return (
      <UiCardAddPhoto
        key={index}
        image={Env.PUBLIC_URL + item}
        remove={() => {
          let _arr = images;
          _arr.splice(index, 1);
          setImages(_arr);
          setLoad(true)
          setTimeout(() => setLoad(false), 10)
        }}
      />
    )
  })

  if (props.data?.ratingList) {
    var ratingList = props.data.ratingList.map((item, index) => {
      return (
        <UiCardMsg
          sender={`${item.name} ⭐ ${item.rating}/5`}
          text={item.text}
        />
      )
    })
  }


  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-deal">
        <div className="modal-deal-wrap">
          {/**
           * modal left side - static
           */}
          <div className="modal-deal-about add">
            {/**
             * modal title
             */}
            <div className="modal-deal-about-title">
              <div className="modal-deal-about-title-wrap">
                <h3>
                  {props.data ? "Информация о работнике" : "Создать работника"}
                </h3>
              </div>
              <div className="modal-deal-about-buttons"> </div>
            </div>
            {/**
             * modal notifications + msgs
             */}
            <div className="modal-deal-notes">
              <div className="modal-deal-notes-list-wrap">
                <div className="modal-deal-info">
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Имя Фамилия"
                      value={name}
                      onChange={(val) => setName(val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">

                    <UiTextInputMasked
                      label="Телефон"
                      placeholder="Введите Телефон.."
                      value={phone}
                      onChange={(val) => {
                        var str = val.replace(/[^0-9]/g, '');
                        setPhone(str)
                      }}
                    />

                  </div>
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Куда платить"
                      value={paymentInfo}
                      onChange={(val) => setPaymentInfo(val)}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Адрес регистрации"
                      value={contact}
                      onChange={(val) => setContact(val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Адрес проживания"
                      value={fContact}
                      onChange={(val) => setFContact(val)}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Комментарий"
                      value={comment}
                      onChange={(val) => setComment(val)}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiDropDown
                      label="Статус"
                      items={[
                        { value: 0, text: "Ожидает рассмотрения" },
                        { value: 1, text: "ОДОБРЕН" }
                      ]}
                      onChange={(val) => setConfirm(val)}
                    />
                  </div>


                  <div className="modal-deal-info-form">
                    {props.data ? (
                      <UiButtonColor
                        color="primary"
                        text="Обновить"
                        small={false}
                        onClick={() => {
                          props.onEdit({
                            id: props.data.id,
                            name: name,
                            phone: phone,
                            contact: contact,
                            comment: comment,
                            image: images,
                            rating: rating,
                            payment_info: paymentInfo,
                            fact_contact: fContact,
                            is_confirm: isConfirm,
                            is_worker: isWorker,
                            car: car,
                            car_size: carSize,
                            car_carrying: carCarrying,
                            car_volume: carVolume,
                            is_del: 0
                          });
                        }}
                      />
                    ) : (
                      <UiButtonColor
                        color="primary"
                        text="Создать"
                        small={false}
                        onClick={() => {
                          props.onCreate({
                            name: name,
                            phone: phone,
                            contact: contact,
                            comment: comment,
                            image: images,
                            payment_info: paymentInfo,
                            fact_contact: fContact,
                            is_confirm: isConfirm,
                            car: car,
                            car_size: carSize,
                            car_carrying: carCarrying,
                            car_volume: carVolume
                          });
                        }}
                      />
                    )}


                  </div>


                </div>
              </div>
            </div>
          </div>
          {/**
           * modal right side - tabs
           */}
          <div className="modal-deal-info-wrap">
            {/** close button */}
            <button
              className="modal-deal-info-close"
              onClick={() => props.modalClose()}
            >
              <SvgClose className="modal-deal-info-close-icon" />
            </button>
            <div className="modal-deal-info">
              <h3>Фотографии (Фото, Паспорт, СТС)</h3>
              <div className="modal-deal-photo-add">
                <UiCardAddPhoto
                  image={null}
                  isLoad={load}
                  upload={(file) => uploadList(file)}
                  remove={() => {

                  }}
                />
                {!load ? imagesList : null}
              </div>


              <div className="modal-deal-info-form">


                <UiInputText
                  label="Машина"
                  value={car}
                  onChange={(val) => setCar(val)}
                />

                <UiInputText
                  label="Габариты а/м."
                  value={carSize}
                  onChange={(val) => setCarSize(val)}
                />

                <UiInputText
                  label="Грузоподъемность (тонны)"
                  type="number"
                  value={carCarrying}
                  onChange={(val) => setCarCarrying(val)}
                />

                <UiInputText
                  label="Объем (м³)"
                  value={carVolume}
                  type="number"
                  onChange={(val) => setCarVolume(val)}
                />

                {props.data ? (

                  <UiInputText
                    label="Рейтинг ⭐"
                    value={rating}
                    onChange={(val) => setRating(val)}
                  />
                ) : null}


                <UiButtonColor
                  color="orange"
                  text="Удалить"
                  small={false}
                  onClick={() => {
                    props.onEdit({
                      id: props.data.id,
                      name: name,
                      phone: phone,
                      contact: contact,
                      comment: comment,
                      image: images,
                      rating: rating,
                      payment_info: paymentInfo,
                      fact_contact: fContact,
                      is_confirm: isConfirm,
                      is_worker: isWorker,
                      car: car,
                      car_size: carSize,
                      car_carrying: carCarrying,
                      car_volume: carVolume,
                      is_del: 1
                    });
                  }}
                />
              </div>

              {props.data?.ratingList ? <h3>Отзывы пользователя</h3> : null}
              <div className="modal-deal-info-form">
                {props.data?.ratingList ? ratingList : null}
              </div>

            </div>

          </div>
        </div>
      </div>
    </UiModal>
  );
}

export default UiModalUser;
