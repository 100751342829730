import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SvgBox } from "../../assets/images/menu/box.svg";
import { ReactComponent as SvgLeafs } from "../../assets/images/info/ant.svg";

import UiBtnColor from "../button/UiButtonColor";

import "./UiSectionTitle.scss";

const UiSectionTitle = (props) => {
  let navigate = useNavigate();

  return (
    <div className={"section-title" + (props.align ? " " + props.align : "")}>
      <div className="section-title-logo">
        <SvgBox className="section-title-icon2" />
        <SvgLeafs className="section-title-icon" />
      </div>
      <h3>{props.title}</h3>
      <p>{props.text}</p>
      {props.buttonText ? (
        <UiBtnColor color="brand" text={props.buttonText} />
      ) : null}
    </div>
  );
};
export default UiSectionTitle;
