export default {
    VOLUME_ARRAY: [
        { value: 0, text: "до 1,5 кубов" },
        { value: 1, text: "от 1,5 до 2,5 кубов" },
        { value: 2, text: "от 7 до 10 кубов" },
        { value: 3, text: "от 10 до 13 кубов" },
        { value: 4, text: "от 13 до 16 кубов" },
        { value: 5, text: "от 16 до 20 кубов" },
        { value: 6, text: "от 20 до 40  кубов" },
        { value: 7, text: "свыше 40 кубов" },
      ],
    TRASH_ARRAY: [
        { value: 0, text: "до 1 тонны " },
        { value: 1, text: "от 1 до 1,5 тонн" },
        { value: 2, text: "от 1,5 до 2 тонн" },
        { value: 3, text: "свыше 2 тонн" },
      ],
    TAK_ARRAY: [
        { value: 0, text: "1 этаж" },
        { value: 1, text: "Такелаж сейфа до 150 кг" },
        { value: 2, text: "Такелаж сейфа до 250 кг" },
        { value: 3, text: "Такелаж сейфа свыше 250 кг" },
        { value: 4, text: "Такелаж сейфа свыше 250 кг" },
      ],
      UNBUILD_ARRAY: [
        { value: 0, text: "до 1 метра" },
        { value: 1, text: "от 1 до 2,5 метра" },
        { value: 2, text: "от 2,5 и выше" },
      ],
      PACK_ARRAY: [
        { value: 0, text: "Стретч пленка" },
        { value: 1, text: "Пупырчатая пленка" },
      ],
      MOVING_TYPE: [
        { value: 0, text: "Офисный" },
        { value: 1, text: "Домашний (коробки)" },
        { value: 2, text: "Домашний (габарит)" },
        { value: 3, text: "Стройматериалы" },
        { value: 4, text: "Вывоз мусора" },
      ],
      PRR_TYPE: [
        { value: 0, text: "Без ПРР" },
        { value: 1, text: "С ПРР" },
      ],
      LIFT_TYPES: [
        { value: 0, text: "Нет" },
        { value: 1, text: "Обычный" },
        { value: 2, text: "Грузовой" },
      ],

      ORDER_TYPES_NAMES: [
        "Принятая заявка",
        "Заявка в работу",
        "Подготовка для выгрузки",
        "Отправлены инструкции",
        "Ожидает исполнения",
        "Исполняется ЗАВТРА!",
        "Дальний рейс",
        "Контроль старта(делается сегодня)",
        "Закончен, ожидает оплаты",
        "Ожидают проставления рейтинга",
        "БЕЗНАЛ",
        "Архивация",
      ],
      ORDER_STATUS_NEW: 0,
      ORDER_STATUS_WAIT: 1,
      ORDER_STATUS_SEND: 2,
      ORDER_STATUS_CONTROL: 3,
      ORDER_STATUS_RAIT: 4,
      ORDER_STATUS_DONE: 5,

      ORDER_TYPES_COLORS: [
        "purple", "grass", "dark", "blue", "orange", "cyan",
        "purple", "grass", "dark", "blue", "orange", "cyan",
        "purple", "grass", "dark", "blue", "orange", "cyan",
      ],

      UPDATE_INFO_NEW_STATUS: 'new_status',
      UPDATE_INFO_CHANGE_INFO: 'change_info',
      UPDATE_INFO_CHANGE_PRICE: 'change_price',
      UPDATE_INFO_NEW_WORKER: 'new_worker',
  };